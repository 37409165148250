import React, { FunctionComponent } from 'react';
import { graphql } from 'gatsby';
import Moment from 'react-moment';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Image from '../components/image';
import ReactMarkdown from 'react-markdown';
import CodeBlock from '../components/elements/code-block';
import LoveButton from '../components/elements/love-button';
import { Global, PageContext } from '@/utils/types';
import { Article } from '@/models/articles';

interface ArticleProps {
  data: {
    strapiGlobal: Global;
    strapiArticle: Article;
  };
  pageContext: PageContext;
}

const ArticlePage: FunctionComponent<ArticleProps> = ({
  data,
  pageContext,
}) => {
  const { localizations } = data.strapiArticle;
  const global = data.strapiGlobal;
  const article = data.strapiArticle;
  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.image,
    article: true,
    published_at: article.published_at,
  };

  return (
    <>
      <SEO seo={seo} global={global} pageContext={pageContext} />
      <Layout global={global} pageContext={{ ...pageContext, localizations }}>
        <div className="flex flex-col">
          <div>
            <Image
              style={{ maxHeight: '400px' }}
              alt={`Picture for ${article.title} article`}
              media={article.image}
              // layout="fullWidth"
            />
          </div>

          <div className="container">
            <h1 className="dark:text-white text-center heading1 my-4">
              {article.title}
            </h1>
            <h2 className="dark:text-textlightblue text-center heading2">
              {article.description}
            </h2>
            <ReactMarkdown
              className="container text-justify my-10 prose lg:prose-lg dark:prose-dark dark:lg:prose-lg-dark"
              children={article.content}
              components={{ code: CodeBlock }}
            />

            <hr className="my-4" />

            <div className="flex justify-start align-middle gap-2">
              <div>
                {article.author.picture && (
                  <Image
                    media={article.author.picture}
                    alt={`Picture of ${article.author.name}`}
                    style={{ borderRadius: '50%' }}
                  />
                )}
              </div>
              <div>
                <p className="font-medium dark:text-textlightblue">
                  {article.author.name}
                </p>
                <p className="dark:text-textlightblue">
                  <Moment format="MMM Do YYYY">{article.published_at}</Moment>
                </p>
              </div>
              <div className="flex-1" />
              <LoveButton slug={article.slug} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ArticlePage;

export const query = graphql`
  query ArticleQuery($id: String!, $locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    strapiArticle(id: { eq: $id }) {
      slug
      strapiId
      title
      description
      content
      published_at
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
      }
      author {
        name
        picture {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 30)
            }
          }
        }
      }
      localizations {
        id
        locale
      }
    }
  }
`;
